<template>
  <div>
    <div class="text-center mb-5">
      <h2 class="my-5">
        <strong v-if="settings.logo"
          >{{ $t("login.bienvenue") }} {{ settings.logo }}</strong
        >
        <strong v-else>{{ $t("login.title") }} </strong>
      </h2>
    </div>
    <div
      class="card mb-0"
      :class="$style.container"
      style="width: 100%; margin: auto"
    >
      <div class="text-dark font-size-24 mb-3 mt-0">
        <strong>{{ $t("login.connectezVous") }}</strong>
      </div>
      <p class="mt-0 mb-0">
        {{ $t("login.subscribePart1") }}
        <a
          class="terms-link"
          href="https://edtrust.tn/subscribe.php"
          target="_blank"
          ><b>{{ $t("login.subscribePart2") }}</b></a
        >
        {{ $t("login.subscribePart3") }}
      </p>
      <div class="mb-4"></div>
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            size="large"
            :placeholder="$t('login.username')"
            v-decorator="[
              'userName',
              {
                rules: [
                  {
                    required: true,
                    message: $t('login.requisUsername'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item class="mb-3">
          <a-input
            size="large"
            :placeholder="$t('profil.mdp')"
            type="password"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: $t('login.requisMdp'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-checkbox class="mb-2 mt-0" @change="acceptedTerms = !acceptedTerms">
          {{ $t("login.readAndAccept") }}
          <a :href="termsLink" target="_blank" class="terms-link"
            ><b>{{ $t("login.termsAndConditions") }}.</b></a
          >
        </a-checkbox>
        <a-button
          :type="acceptedTerms ? 'primary' : ''"
          htmlType="submit"
          size="large"
          class="text-center w-100"
          :disabled="!acceptedTerms"
          :loading="loading"
        >
          <strong>{{ $t("login.connextion") }}</strong>
        </a-button>
      </a-form>
      <a-dropdown
        :trigger="['click']"
        placement="bottomCenter"
        style="margin: auto"
      >
        <div :class="$style.dropdown">
          <button
            type="button"
            class="btn btn-light dropdown-toggle dropdown-toggle-noarrow"
          >
            <i class="fe fe-more-horizontal" /><img
              style="border-radius: 25%"
              width="20"
              :src="`resources/images/flags/${
                language == 'en'
                  ? 'united-states'
                  : language == 'fr'
                  ? 'france'
                  : 'tunisia'
              }.svg`"
              alt="Arabic"
            />
            {{ $t("language." + language) }}
          </button>
        </div>

        <a-menu slot="overlay" @click="selectLocale">
          <a-menu-item key="en-US">
            <img
              style="border-radius: 25%"
              width="20"
              src="resources/images/flags/united-states.svg"
              alt="Arabic"
            />
            {{ $t("language.en") }}
          </a-menu-item>
          <a-menu-item key="fr-FR">
            <img
              style="border-radius: 25%"
              width="20"
              src="resources/images/flags/france.svg"
              alt="Arabic"
            />
            {{ $t("language.fr") }}
          </a-menu-item>
          <a-menu-item key="ar-AR">
            <img
              style="border-radius: 30%"
              width="20"
              src="resources/images/flags/tunisia.svg"
              alt="Arabic"
            />
            {{ $t("language.ar") }}
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapState } from "vuex";
import store from "store";
import router from "@/router";
import apiClient from "@/services/axios";
export default {
  name: "CuiLogin",
  computed: {
    ...mapState(["settings"]),
    loading() {
      return this.$store.state.user.loading;
    },
    language() {
      return this.settings.locale.substr(0, 2);
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      termsLink: process.env.VUE_APP_TERMS_LINK,
      acceptedTerms: false,
    };
  },
  methods: {
    changeAuthProvider(value) {
      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "authProvider",
        value,
      });
    },
    selectLocale(item) {
      const setting = "locale";
      this.$store.commit("settings/CHANGE_SETTING", {
        setting,
        value: item.key,
      });
    },
    getCurrentScholarYearId(schoolYears) {
      const currentDate = new Date();

      for (const schoolYear of schoolYears) {
        const startDate = new Date(schoolYear.start);
        const endDate = new Date(schoolYear.end);

        if (currentDate >= startDate && currentDate <= endDate) {
          return schoolYear._id;
        }
      }

      // If no matching school year is found, you can return null or handle it as needed.
      return schoolYears[0]._id;
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store
            .dispatch("user/LOGIN", { payload: values })
            .then(async (user) => {
              /*if (user)
                this.$notification.success({
                  message: this.$t("login.connecte"),
                  description: this.$t("login.loginSuccess"),
                });*/

              await this.$store.commit("settings/CHANGE_SETTING", {
                setting: "activeBuilding",
                value: user.building[0].dbName,
              });
              if (user.building[0].base_url) {
                apiClient.defaults.baseURL =
                  user.building[0].base_url + "/api/web";
                await this.$store.commit("settings/CHANGE_SETTING", {
                  setting: "base_url",
                  value: user.building[0].base_url,
                });
              }

              await this.$store.commit("settings/CHANGE_SETTING", {
                setting: "activeBuildingId",
                value: user.building[0]._id,
              });
              await this.$store.commit("settings/CHANGE_SETTING", {
                setting: "image",
                value: user.building.map((b) => {
                  return {
                    name: b.name,
                    db: b.dbName,
                    dbName: b.dbName,
                    headMaster: b.headMaster,
                    logo:
                      (b.base_url ? b.base_url : this.settings.base_url) +
                      "/" +
                      b.logo,
                  };
                }),
              });
              await store.set("DB", user.building[0].dbName);
              store.set("app.topbar.favs", user.fav);
              apiClient
                .post("/schoolaryears/filter", {
                  query: { $query: {}, $orderby: { end: -1 } },
                })
                .then(async (res) => {
                  const data = res.data.map((sc) => {
                    const start = new Date(sc["start"]);
                    const end = new Date(sc["end"]);
                    const startM =
                      start.getMonth() < 9
                        ? "0" + (start.getMonth() + 1)
                        : "" + (start.getMonth() + 1);
                    const endM =
                      end.getMonth() < 9
                        ? "0" + (end.getMonth() + 1)
                        : "" + (end.getMonth() + 1);
                    return {
                      ...sc,
                      name:
                        startM +
                        "-" +
                        start.getFullYear() +
                        " / " +
                        endM +
                        "-" +
                        end.getFullYear(),
                    };
                  });
                  let activeSchoolarYearId = this.getCurrentScholarYearId(data);
                  await this.$store.commit("settings/CHANGE_SETTING", {
                    setting: "activeSchoolarYear",
                    value: activeSchoolarYearId,
                  });
                  await this.$store.commit("settings/CHANGE_SETTING", {
                    setting: "schoolarYears",
                    value: data,
                  });
                  await store.set(
                    "app.settings.activeSchoolarYear",
                    activeSchoolarYearId
                  );
                  await store.set("activeSchoolarYear", activeSchoolarYearId);
                  await store.set("SchoolarYear", activeSchoolarYearId);
                  //get all routes
                  let path = this.getFirstAcessPath(user.role.access);
                  console.info("redirect :", path);
                  router.push(path);
                });
            })
            .catch(() => {
              this.form.resetFields();
            });
        }
      });
    },
    getFirstAcessPath(access) {
      let firstAcess = {};
      let path = "";
      if (access.all === true || access.home === true) path = "/accueil";
      else {
        for (let key of Object.keys(access)) {
          if (access[key] === true || access[key].length > 0) {
            firstAcess = {
              key: key,
              role: Array.isArray(access[key])
                ? access[key].filter((e) => e !== "all")
                : access[key],
            };

            break;
          }
        }
        let routes = router.options.routes[0].children;
        path = routes.find(
          (e) =>
            (e.meta.accessGroup == firstAcess.key &&
              e.meta.roles.includes(firstAcess.role[0])) ||
            (e.meta.accessGroup == firstAcess.key && firstAcess.role === true)
        ).path;
      }
      return path;
    },
  },
};
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>

<style scoped>
.terms-link {
  color: #3583e9;
}
.terms-link:hover {
  text-decoration: underline;
}
</style>
